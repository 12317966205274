import styled, { css } from "styled-components"
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";
import heroImage from "../../../../static/images/podcast-hero.webp"

export const HeroWrapper = styled.div`
  background-position: left bottom 200px!important;
  background-repeat: no-repeat!important;
  margin-top: 100px;
  background: url(${heroImage});
  padding: 0 30px;
  background-size: cover;
  @media (min-width: 993px) {
    background-position: left bottom!important;
  }
`

export const Hero = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 506px;
  @media (min-width: 577px) {
    max-width: 770px;
  }
  @media (min-width: 993px) {
    max-width: 1170px;
  }
`

export const BreadCrumbFlex = styled.div`
  padding: 24px 0;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  a {
    text-decoration: none;
    span {
      ${font('text', 'sm', '400')}
    }
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    ${font('text', 'sm', '700')}
    color: ${color.common.white};
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #dddddd;
    }
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const HeroContentWrap = styled.div`
  display: grid;
  gap: 40px;
  justify-content: center;
  text-align: center;
  padding: 24px 0 0;
  @media (min-width: 577px) {
    padding: 32px 0 0;
  }
  @media (min-width: 993px) {
    text-align: left;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 40px 0 96px;
  }
`

export const TextSide = styled.div`
  display: grid;
  gap: 32px;
  height: fit-content;
  margin: auto 0;
`

export const HeadingWrap = styled.div`
  display: grid;
  gap: 16px;
`

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${color.common.white};
  align-items: center;
  justify-content: center;
  ${props => props && props.blue && css`
    margin-top: -8px;
    flex-direction: row;
    color: ${color.common.black};
    svg {
      path {
        fill: ${color.primary[600]};
      }
    }
    @media (min-width: 993px) {
      margin-top: 0;
    }
  `}
  @media (min-width: 577px) {
    flex-direction: row;
    gap: 32px;
  }
  @media (min-width: 993px) {
    justify-content: unset;
  }
  a {
    transition: opacity 150ms ease;
    :hover{
      opacity: 0.75;
    }
  }
`

export const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Text = styled.p`
  ${font('text', 'reg', '400')}
  ${props => props && props?.bold && css`
    ${font('text', 'reg', '600')}
  `}
`

export const Eyebrow = styled.span`
  ${font('kicker', 'reg', '900')}
  color: ${color.common.electricBlue};
`

export const Heading = styled.h1`
  color: ${color.common.white};
  ${font('display', 'md', '900')}
  @media (min-width: 577px) {
    ${font('display', 'lg', '900')}
  }
`

export const ImgWrap = styled.div`
  background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.36) 28.69%, rgba(255, 255, 255, 0.27) 100%);
  box-shadow: 0px 20px 30px rgba(16, 24, 40, 0.05);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  padding: 16px;
  margin: 0 auto;
  @media (min-width: 577px) {
    padding: 24px;
  }
  @media (min-width: 993px) {
    margin: auto 0;
  }
`
